








import { Component, Prop, Vue } from 'vue-property-decorator';
import codeselect from './InsCodeSelect.vue';
import langSelect from './InsLangSelect.vue';

@Component({
  components: {
    codeselect,
    langSelect
  }
})
export default class InsSwitchGroup extends Vue {}
